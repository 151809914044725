<template>
    <div id="pageTable">
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-0">
                    <v-breadcrumbs :items="itemsLinks">
                        <template v-slot:divider>
                            <v-icon>chevron_right</v-icon>                            
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12">
                    <data-table
                        ref="tablaFormatos"
                        :tableName="'Documentos disponibles'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :showAdvancedFilters="false"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="filters">
                            <!--Espacio para busqueda avanzada-->
                        </template>

                        <tbody slot="body" slot-scope="{data}">
                            <tr :key="item.id" v-for="item in data">
                                <td>
                                    <div class="tblPrincipal"> {{ item.nombre }} </div>
                                </td>
                                <td>
                                    <div class="tblOpciones align-center">
                                        <v-switch
                                            label="Aplica"
                                            v-model="item.aplica"
                                            @change="updateDocumento(item)"
                                        ></v-switch>
                                        <v-menu button right class="elevation-0 d-inline" content-class="mt-0 menuClassOpciones">
                                            <template v-slot:activator="{on, attrs}">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon 
                                                    fab
                                                    dark
                                                    small
                                                    class="elevation-0"
                                                >
                                                    <template  v-for="(item,valor) in attrs" >
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                    </template>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                    @click="abrirModalAsignar(item)"
                                                >
                                                    <v-list-item-title>Asignar</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    @click="eliminar(item)"
                                                >
                                                    <v-list-item-title>Eliminar</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                       <!--  <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    slot="activator"
                                                    class="botonHover ml-3"
                                                    depressed
                                                    icon
                                                    fab
                                                    dark
                                                    small
                                                    @click="eliminar(item)"
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip> -->    
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="dialogAsignar" persistent max-width="650px" class="borde-card">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
            </template>
            <v-card class="borde-card">
                <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                    <div class="headerModalStart">
                        <h2 class="titleModal_700_18">{{ tituloModal }}</h2>
                    </div>
                </v-card-title>
                <v-card-text class="card_texto">
                    <v-container grid-list-md id="contenedor">
                        <v-row class="my-5">
                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                <MultipleAutocomplete
                                    outlined
                                    :items="empleados"
                                    :valorDefecto="empleados_seleccionados"
                                    item_text="nombre_completo"
                                    @dataMultiple="(data)=> empleados_seleccionados = data"
                                    label="Todos los empleados"
                                    ref="multSucursal"
                                />
                            </v-col>  
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="d-flex justify-end card_accion">
                    <button
                        class="btnCerrar"
                        @click="cerrarModal()" 
                        :disabled="isSaving"
                    >
                        Cancelar
                    </button>
                    <v-btn
                        class="btnGuardar"
                        @click="asignar()" 
                        :loading="isSaving"
                    >
                        Asignar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Datatable from "@/components/datatable/Datatable.vue";
    import Notify from "@/plugins/notify"
    import sucursalesApi from "@/api/sucursales"
    import MultipleAutocomplete from "../components/VuetifyMultipleAutocomplete2.vue"
    import contratosApi from "@/api/contratos"

    export default {
        components:{
            'data-table': Datatable,
            MultipleAutocomplete,
        },
        data(){
            return{
                url: 'contratos-formatos/find',
                columns: [
                    {
                        label: "Documento",
                        name: "documento",
                        filterable: false,
                    },

                    {
                        label: "Opciones",
                        name: "opciones",
                        align: 'center',
                        filterable: false,
                    },
                ],
                isLoading: false,
                datosLogin: null,
                isSaving: false,
                rol: '',
                filters:{
                    sucursal_id: null,
                    es_otro_documento: true,
                    sistema: 'nominas',
                    activo: true,
                },
                formatos_seleccionados:[],
                aplicado: false,
                itemSucursal: null,
                itemsLinks : [
                    {
                        text: 'Sucursales',
                        disabled: false,
                        to: '/configuracion/sucursales'
                    }
                ],
                dialogAsignar: false,
                empleados: [],
                empleados_seleccionados: [],
                tituloModal: '',
                documento_id: null
            }
        },
        methods: {
            listar(){
                this.datosLogin   = this.$session.get('usuario');
                this.itemSucursal = this.$session.get('documentosSucursal')
                this.rol = this.datosLogin.rol.name;
                this.filters.sucursal_id = this.itemSucursal.id

                this.itemsLinks.push({
                    text: this.itemSucursal.nombre,
                    disabled: true,
                    to: '/documentos_activos'
                })
            },
            updateDocumento(item){
                let Val = item.aplica
                Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas aplicar el cambio en " + item.nombre + "?",
                "Aplicar",
                () => {
                    this.isLoading = true;
                    sucursalesApi.updateEstatusFormato({id: item.id, accion: 'update'}).then(resp => {
                        Notify.Success("Cambio Aplicado", "Se aplicaron los cambios satisfactoriamente")
                        this.$refs.tablaFormatos.getData()
                    }).catch(err =>{
                        this.isLoading = false
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    })
                },
                () => {
                    item.aplica = !Val
                }
            )
            },
            eliminar(item){
                Notify.Alert(
                    "ADVERTENCIA",
                    "¿Estás seguro que deseas eliminar el Documento " + item.nombre + "?",
                    "Eliminar",
                    () => {
                        this.isLoading = true;
                        sucursalesApi.updateEstatusFormato({id: item.id, accion: 'delete'}).then(resp => {
                            Notify.Success("Documento eliminado", "El documento se eliminó satisfactoriamente")
                            this.$refs.tablaFormatos.getData()
                        }).catch(err =>{
                            this.isLoading = false
                            if(typeof err.response.data.error === 'string'){
                                Notify.ErrorToast(err.response.data.error);
                            }else{
                                let error = Object.values(err.response.data.error);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }

                                Notify.ErrorToast(leyenda);
                            }
                        })
                    }
                ) 
            },
            
            async getEmpleadosDocumentos(){

                let params = {
                    contrato_formato_id: this.documento_id
                }

                let empleadosFiltrados = []

                contratosApi.getEmpleadosDocumentos(params).then((resp)=>{
                    this.empleados = resp
                    //console.log(this.empleados);
                    empleadosFiltrados = this.empleados.filter((el) => el.tiene_otro_documento == true)
                    this.empleados_seleccionados = empleadosFiltrados.map((item) => item.id)
                    //console.log(this.empleados_seleccionados);
                }).catch((error) => {
                    Notify.ErrorToast("Hubo un error al cargar los empleados")
                    console.log(error);
                })
            },
            async abrirModalAsignar(item){
                this.tituloModal = 'Asignación de documento'
                this.documento_id = item.id
                await this.getEmpleadosDocumentos()

                this.dialogAsignar = true
            },

            cerrarModal(){
                this.dialogAsignar = false
                this.empleados_seleccionados = []
                this.empleados = []
                this.documento_id = null
            },

            asignar(){
                let parametros = {
                    contrato_formato_id: this.documento_id,
                    empleados: this.empleados_seleccionados
                }

                this.isSaving = true;
                contratosApi.generarDocumentoMasivo(parametros).then((resp)=>{
                    Notify.Success("Operación exitosa", "El documento fue asignado satisfactoriamente")
                    this.$refs.tablaFormatos.getData()
                    this.isSaving = false
                    this.cerrarModal()
                }).catch(err =>{
                    this.isSaving = false
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                })
                

            },

            loadModalData(){

                //En caso de filtros
            },
            setFilters(){
                //En caso de filtros
            },
            redireccionar(){
                this.$router.push('/dashboard');
            },
        },
        beforeCreate() {
            if(!this.$session.get('documentosSucursal')){
                this.$router.push('/dashboard')
            }
        },
        created() {
            this.listar()
        },
    }
</script>

<style scoped>
 .menuClassOpciones{
        border-radius: 10px!important;
    }

</style>